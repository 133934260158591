import React from 'react'
import dynamic from 'next/dynamic'
import { makeStyles } from 'tss-react/mui'

const Chart = dynamic(() => import('react-charts').then((mod) => mod.Chart), {
  ssr: false,
})

const useStyles = makeStyles()((theme) => ({
  chartContainer: {
    width: '100%',
    height: 400,
  },
}))

const stateOrder = {
  LIVE: 5,
  REJECTED: 4,
  INFEASIBLE: 3,
  MANUAL: 2,
  NEW: 1,
  TEST_RUN: 0,
}

export default function ScrapeTaskStats({ data }) {
  const { classes } = useStyles()
  const primaryAxis = React.useMemo(
    () => ({
      getValue: (datum) => datum.primary,
    }),
    [],
  )

  const secondaryAxes = React.useMemo(
    () => [
      {
        getValue: (datum) => datum.secondary,
      },
    ],
    [],
  )

  if (data == null) return null

  const transformedData = Object.values(
    data.taskStateStats.reduce((acc, d) => {
      const label = d.state
      const primary = new Date(d.created_at)
      const secondary = d.count

      if (!acc[label]) {
        acc[label] = {
          label,
          data: [],
        }
      }
      acc[label].data.push({
        primary,
        secondary,
      })

      return acc
    }, {}),
  )

  transformedData.forEach((d) => {
    d.data.sort((a, b) => a.primary - b.primary)
  })
  return (
    <div className={classes.chartContainer}>
      <Chart
        options={{
          data: transformedData,
          primaryAxis,
          secondaryAxes,
        }}
      />
    </div>
  )
}
