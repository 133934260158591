import React from 'react'
import { makeStyles } from 'tss-react/mui'
import PageContentWrapper from 'shared-components/common/PageContentWrapper'
import scraperApiClient from '../../src/scraper/ScraperApiClient'
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import ScrapersByRegionChart from 'components/Scraper/stats/ScrapersByRegionChart'
import ScrapeTaskStats from 'components/Scraper/stats/ScrapeTaskStats'

const queryClient = new QueryClient()
const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 'auto',
    maxWidth: 900,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: '100%',
    '& .c-MuiPaper-rounded': {
      borderRadius: '16px',
    },
  },
}))

const AdminPageReview = ({ admin, scraper }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ScraperStats admin={admin} scraper={scraper} />
    </QueryClientProvider>
  )
}

const ScraperStats = ({ admin, scraper }) => {
  const { classes } = useStyles()

  const dispatch = useDispatch()
  // const [data, setData] = React.useState([])

  const regionStats = useQuery({
    queryKey: ['region-stats'],
    queryFn: scraperApiClient.stats.getRegionScrapeBotStats,
    refetchOnWindowFocus: false,
    refetchInterval: 5 * 60 * 1000, // 5 minutes
  })

  const taskStats = useQuery({
    queryKey: ['task-stats'],
    queryFn: scraperApiClient.stats.getScrapeTaskStats,
    refetchOnWindowFocus: false,
    refetchInterval: 5 * 60 * 1000, // 5 minutes
  })

  return (
    <PageContentWrapper
      large
      id='app-root'
      fullWidth
      className={classes.background}
    >
      <div className={classes.container}>
        <ScrapersByRegionChart data={regionStats.data} />
        <ScrapeTaskStats data={taskStats.data} />
        {/* <Paper>
          <Typography variant='h5'>Failed Scrape Task Info</Typography>
          {taskStats.data.infoCounts.map((info) => (
            <div key={info.info}>
              <Typography variant='h6'>{info.info}</Typography>
              <Typography variant='body1'>Count: {info.count}</Typography>
              <Typography variant='body1'>
                Last seen {dayjs(info.lastSeen).format()}
              </Typography>
              <hr />
            </div>
          ))}
        </Paper> */}
      </div>
    </PageContentWrapper>
  )
}

export { adminProtectedGetServerSideProps as getServerSideProps } from 'utils/pages'

export default AdminPageReview
