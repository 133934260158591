import React, { Dispatch, SetStateAction } from 'react'

export const insightsBreakpoint = 700

export enum InsightsPage {
  REGISTRATIONS = 'REGISTRATIONS',
  REACH = 'REACH',
  GUESTS = 'GUESTS',
}

export enum InsightHighlightType {
  TICKETS_SOLD = 'TICKETS_SOLD',
  REVENUE = 'REVENUE',
  TIME_UNTIL_START = 'TIME_UNTIL_START',
  REACHED_BY_HOST = 'REACHED_BY_HOST',
  REACHED_THROUGH_FRIENDS = 'REACHED_THROUGH_FRIENDS',
  EXTRA_REVENUE_THROUGH_FRIENDS = 'EXTRA_REVENUE_THROUGH_FRIENDS',
  RETURNING_GUESTS = 'RETURNING_GUESTS',
  BROUGHT_FRIENDS = 'BROUGHT_FRIENDS',
  FOLLOWERS = 'FOLLOWERS',
}

export interface InsightHighlight {
  type: InsightHighlightType
  value: number | null
}

export interface InsightsTotalBreakdownItem {
  type: string
  value: number
}

export interface InsightsPageResult {
  total: number
  highlights: InsightHighlight[]
}

export interface InsightsResult {
  [InsightsPage.REGISTRATIONS]: InsightsPageResult
  [InsightsPage.REACH]: InsightsPageResult
  [InsightsPage.GUESTS]: InsightsPageResult
}

export interface InsightsBarDataItem {
  count: number
  type: string
  items: InsightsTotalBreakdownItem[]
}

export interface InsightsPieDataItem {
  text: string
  promille: number
}

export enum InsightsGuestsType {
  AGE = 'AGE',
  RESIDENCE = 'RESIDENCE',
  VISIT_COUNT = 'VISIT_COUNT',
}

export interface InsightsGuestsResult {
  [InsightsGuestsType.AGE]: InsightsPieDataItem[]
  [InsightsGuestsType.RESIDENCE]: InsightsPieDataItem[]
  [InsightsGuestsType.VISIT_COUNT]: InsightsPieDataItem[]
}

export enum InsightsReachType {
  HOST_INVITES = 'HOST_INVITES',
  HOST_REACH = 'HOST_REACH',
  SOCIAL_REACH = 'SOCIAL_REACH',
}

export interface InsightsReachResult {
  [InsightsReachType.HOST_INVITES]: InsightsBarDataItem[]
  [InsightsReachType.HOST_REACH]: InsightsBarDataItem[]
  [InsightsReachType.SOCIAL_REACH]: InsightsPieDataItem[]
}

export interface InsightsContextValue {
  page: InsightsPage
  setPage: Dispatch<SetStateAction<InsightsPage>>
  loading: boolean
  result: InsightsResult | null
}

export const insightsContext = React.createContext<InsightsContextValue>({
  page: InsightsPage.REGISTRATIONS,
  setPage: () => {},
  loading: true,
  result: null,
})
