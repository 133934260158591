import Row from '../../Row'
import { Box, CircularProgress, SxProps, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import { insightsBreakpoint, insightsContext } from '../../../types/insights'
import InsightsHighlightItem from './InsightsHighlightItem'
import Column from '../../Column'
import AnimatedStateContent from '../../AnimatedStateContent'

interface Props {
  sx?: SxProps
}

const InsightsHighlightsBar = ({ sx = [] }: Props) => {
  const { t } = useTranslation('common')
  const { loading, result, page } = useContext(insightsContext)

  return (
    <Row
      sx={[
        (theme) => ({
          width: 1,
          gap: 2,
          alignItems: 'stretch',
          height: 86,
          position: 'relative',
          flexDirection: 'row',
          [theme.breakpoints.down(insightsBreakpoint)]: {
            flexDirection: 'column',
            height: 'auto',
            borderTop: `1px solid ${theme.palette.divider}`,
            pt: 2,
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Column
        sx={(theme) => ({
          borderRight: `1px solid ${theme.palette.divider}`,
          justifyContent: 'center',
          pr: 3,
          flexBasis: 180,
          flexShrink: 0,
          [theme.breakpoints.down(insightsBreakpoint)]: {
            flexBasis: 0,
          },
        })}
      >
        <Typography
          variant='h7'
          sx={(theme) => ({
            textAlign: 'right',
            [theme.breakpoints.down(insightsBreakpoint)]: {
              textAlign: 'left',
            },
          })}
        >
          {t('insightsModal.highlights')}
        </Typography>
      </Column>

      <Box
        sx={{
          flexGrow: 1,
          position: 'relative',
        }}
      >
        <AnimatedStateContent
          getContent={({ loading, highlights }) => (
            <Row
              sx={(theme) => ({
                gap: 1.5,
                alignItems: 'center',
                height: 1,
                [theme.breakpoints.down(insightsBreakpoint)]: {
                  flexDirection: 'column',
                  height: 'auto',
                },
              })}
            >
              {!loading &&
                highlights &&
                highlights.map((h) => <InsightsHighlightItem item={h} />)}
              {loading && <CircularProgress size={40} />}
            </Row>
          )}
          state={{
            loading,
            highlights: result != null ? result[page].highlights : null,
          }}
          animateHeight={false}
        />
      </Box>
    </Row>
  )
}

export default InsightsHighlightsBar
