import { Box, CircularProgress, Fade, SxProps, useTheme } from '@mui/material'
import {
  areaElementClasses,
  AreaPlot,
  ChartsAxisHighlight,
  chartsAxisHighlightClasses,
  ChartsGrid,
  chartsGridClasses,
  ChartsXAxis,
  ChartsYAxis,
  lineElementClasses,
  lineHighlightElementClasses,
  LineHighlightPlot,
  LinePlot,
  markElementClasses,
  ResponsiveChartContainer,
} from '@mui/x-charts'
import dayjs from 'dayjs'
import InsightsGraphTooltip from './InsightsGraphTooltip'
import { insightsBreakpoint, InsightsPage } from '../../../types/insights'
import { fontFamily } from '../../../shared-components/typography'
import { useEffect, useMemo, useState } from 'react'
import apiClient from '../../../shared-components/utils/ApiClient'
import { useCurrentEvent } from '../../../utils/hooks'
import InsightsGraphEmptyState from './InsightsGraphEmptyState'
import { Theme } from '@mui/material/styles'

interface Props {
  sx?: SxProps<Theme>
}

enum DataState {
  LOADING,
  LOADED,
  ERROR,
  NONE,
}

interface Data {
  xAxis: Date[]
  yAxisCumulative: number[]
  yAxisRaw: number[]
}

interface PageData {
  state: DataState
  data: Data
}

const InitialData: PageData = {
  state: DataState.NONE,
  data: {
    xAxis: [],
    yAxisCumulative: [],
    yAxisRaw: [],
  },
}

const InsightsTicketsGraph = ({ sx = [] }: Props) => {
  const event = useCurrentEvent()
  const [data, setData] = useState(InitialData)
  const themeHook = useTheme()

  const loadData = async () => {
    if (data.state === DataState.LOADING) {
      return
    }
    if ([DataState.ERROR, DataState.NONE].includes(data.state)) {
      setData((prev) => ({
        ...prev,
        state: DataState.LOADING,
      }))
    }
    try {
      const result: {
        date: string
        count: number
      }[] = await apiClient.insights.getGraphData(
        event.id,
        InsightsPage.REGISTRATIONS,
      )
      const xAxis = result.map((r) => new Date(r.date))
      const yAxisRaw = result.map((r) => r.count)
      const yAxisCumulative = yAxisRaw.reduce(
        (accumulator, currentValue, index) => {
          if (index === 0) {
            accumulator.push(currentValue)
          } else {
            accumulator.push(accumulator[index - 1] + currentValue)
          }
          return accumulator
        },
        [] as number[],
      )

      setData((prev) => ({
        ...prev,
        state: DataState.LOADED,
        data: {
          xAxis,
          yAxisCumulative: yAxisCumulative,
          yAxisRaw: yAxisRaw,
        },
      }))
    } catch (e) {
      console.error(e)
      setData((prev) => ({
        ...prev,
        state: DataState.ERROR,
        data: {
          xAxis: [],
          yAxisRaw: [],
          yAxisCumulative: [],
        },
      }))
    }
  }

  useEffect(() => {
    loadData()
  }, [])

  const defaultData = useMemo(() => {
    const xAxis = Array.from(
      { length: dayjs().diff(event.createdAt, 'day') + 1 },
      (_, index) => dayjs(event.createdAt).add(index, 'day').toDate(),
    )
    const yAxisCumulative = xAxis.map((_, index) => index + 1)

    return {
      xAxis: xAxis,
      yAxisCumulative: yAxisCumulative,
      yAxisRaw: [0, 30],
    }
  }, [event])

  // If not loaded yet, show default data (line going up linearly)
  const dataToShow = data.state === DataState.LOADED ? data.data : defaultData

  const isLoading = data.state === DataState.LOADING

  const isEmpty =
    data.state === DataState.LOADED &&
    dataToShow.yAxisCumulative[dataToShow.yAxisCumulative.length - 1] === 0
  // const isEmpty = true

  return (
    <Box
      sx={[
        {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          overflow: 'hidden',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Box
        sx={{
          position: 'absolute',
          width: 1,
          height: 1,
          zIndex: 10,
          pointerEvents: 'none',
        }}
      >
        <Fade in={isEmpty}>
          <Box
            sx={{
              width: 1,
              height: 1,
            }}
          >
            <InsightsGraphEmptyState page={InsightsPage.REGISTRATIONS} />
          </Box>
        </Fade>
      </Box>
      <ResponsiveChartContainer
        sx={(theme) => ({
          [`& .${markElementClasses.root}`]: {
            display: 'none',
          },
          [`& .${lineHighlightElementClasses.root}`]: {
            fill: theme.palette.primary.main,
            color: theme.palette.primary.main,
            background: theme.palette.primary.main,
          },
          [`& .${lineElementClasses.root}`]: {
            stroke: theme.palette.primary.main,
          },
          [`& .${areaElementClasses.root}`]: {
            fill: 'url(#bashAreaGradient)',
          },
          [`& .${chartsGridClasses.line}`]: {
            strokeDasharray: 4,
          },
          [`& .${chartsAxisHighlightClasses.root}`]: {
            stroke: theme.palette.primary.main,
          },
          opacity: isEmpty ? 0 : isLoading ? 0.5 : 1,
          transition: 'opacity 0.2s ease-in-out',
          [theme.breakpoints.down(insightsBreakpoint)]: {
            bgcolor: 'background.paper',
            borderRadius: 1,
          },
        })}
        xAxis={[
          {
            id: 'dataAxis',
            scaleType: 'utc',
            valueFormatter: (v) => dayjs(v).format('ddd\nMMM DD').toUpperCase(),
            data: dataToShow.xAxis,
          },
        ]}
        series={[
          {
            xAxisId: 'dataAxis',
            id: 'dataSeries',
            type: 'line',
            curve: 'linear',
            data: dataToShow.yAxisCumulative,
            area: true,
            label: 'Data',
            connectNulls: true,
          },
        ]}
      >
        <defs>
          <linearGradient
            x1='50%'
            y1='0'
            x2='50%'
            y2='80%'
            gradientTransform='rotate(0)'
            id='bashAreaGradient'
            gradientUnits='userSpaceOnUse'
          >
            {/*<stop offset='0' stopColor='#8B30F3' />*/}
            <stop offset='0' stopColor={themeHook.palette.primary.main} />
            <stop offset='1' stopColor='white' stopOpacity='0' />
          </linearGradient>
        </defs>

        <ChartsGrid horizontal vertical />
        <ChartsXAxis
          axisId={'dataAxis'}
          disableTicks
          tickLabelInterval={(_, index) => index % 2 === 0}
          sx={(theme) => ({
            '& line': {
              // @ts-expect-error theme does have palette
              stroke: theme.palette.grey[600] + ' !important',
            },
            '& text': {
              fontFamily: fontFamily + ' !important',
              fontWeight: 600 + ' !important',
              fontSize: '0.5rem' + ' !important',
              lineHeight: '0.6rem' + ' !important',
              letterSpacing: '0.39px' + ' !important',
            },
          })}
        />
        <ChartsYAxis
          disableTicks
          tickLabelInterval={(_, index) => index % 2 === 0}
          sx={(theme) => ({
            '& line': {
              // @ts-expect-error theme does have palette
              stroke: theme.palette.grey[600] + ' !important',
            },
            '& text': {
              fontFamily: fontFamily + ' !important',
              fontWeight: 600 + ' !important',
              fontSize: '0.5rem' + ' !important',
              lineHeight: '0.6rem' + ' !important',
              letterSpacing: '0.39px' + ' !important',
            },
          })}
        />
        <ChartsAxisHighlight x={'line'} />
        <AreaPlot />
        <LinePlot />
        <LineHighlightPlot />
        <InsightsGraphTooltip />
      </ResponsiveChartContainer>

      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Fade in={isLoading}>
          <CircularProgress size={48} color='primary' />
        </Fade>
      </Box>
    </Box>
  )
}

export default InsightsTicketsGraph
