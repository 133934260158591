import FullscreenDialog from '../FullscreenDialog'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../../../actions/modals'
import BashDialogTitle from '../BashDialogTitle'
import { useTranslation } from 'react-i18next'
import DialogContent from '@mui/material/DialogContent'
import Column from '../../Column'
import { useEffect, useState } from 'react'
import Row from '../../Row'
import InsightsTicketsGraph from './InsightsTicketsGraph'
import {
  insightsBreakpoint,
  insightsContext,
  InsightsPage,
  InsightsResult,
} from '../../../types/insights'
import InsightsMenu from './InsightsMenu'
import apiClient from '../../../shared-components/utils/ApiClient'
import { useCurrentEvent } from '../../../utils/hooks'
import InsightsReach from './Reach/InsightsReach'
import InsightsHighlightsBar from './InsightsHighlightsBar'
import AnimatedStateContent from '../../AnimatedStateContent'
import { Box } from '@mui/material'
import InsightsGuests from './Guests/InsightsGuests'

const InsightsModal = () => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const open = useSelector((state: any) => state.modals.open.insights)
  const event = useCurrentEvent()
  const [page, setPage] = useState<InsightsPage>(InsightsPage.REGISTRATIONS)

  const [result, setResult] = useState<InsightsResult | null>(null)
  const [loading, setLoading] = useState(false)

  const onClose = () => {
    dispatch(closeModal('insights'))
  }

  const setPlaceholderResults = () => {
    setResult((prev) => ({
      ...prev,
      [InsightsPage.REGISTRATIONS]: {
        total: 30000,
        highlights: [
          {
            type: 'RETURNS',
            value: 300,
            value_type: 'percentage',
          },
          {
            type: 'RETURNS',
            value: 300,
            value_type: 'percentage',
          },
          {
            type: 'RETURNS',
            value: 300,
            value_type: 'percentage',
          },
        ],
      },
      [InsightsPage.REACH]: {
        total: 30000,
        highlights: [],
      },
      [InsightsPage.GUESTS]: {
        total: 30000,
        highlights: [],
      },
    }))
  }

  const loadInsights = async () => {
    setLoading(true)
    setResult(null)
    // await sleep(1000)
    try {
      const result = await apiClient.insights.getInsights(event.id)
      if (result) {
        setResult(result)
      } else {
        setPlaceholderResults()
      }
    } catch (e) {
      console.log('CATCH')
      setPlaceholderResults()
      console.error(e)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (open) {
      loadInsights()
    } else {
      setResult(null)
    }
  }, [open])

  const contextValue = {
    page,
    setPage,
    loading,
    result,
  }

  return (
    <insightsContext.Provider value={contextValue}>
      <FullscreenDialog
        slotProps={{
          paper: {
            sx: (theme) => ({
              maxWidth: 940,
              maxHeight: 538,
              height: 1,
              [theme.breakpoints.down(insightsBreakpoint)]: [
                {
                  maxHeight: '100%',
                  maxWidth: '100%',
                },
              ],
            }),
          },
        }}
        open={open}
        onClose={onClose}
      >
        <BashDialogTitle title={t('insightsModal.title')} onClose={onClose} />

        <DialogContent
          sx={{
            p: 3,
            bgcolor: 'grey.150',
            display: 'flex',
            gap: 3,
            border: 'none',
            flexGrow: 1,
            flexDirection: 'column',
          }}
          dividers
        >
          <Column
            sx={{
              width: 1,
              gap: 3,
              alignItems: 'flex-start',
              height: 'fit-content',
              flexGrow: 1,
            }}
          >
            <Row
              sx={(theme) => ({
                gap: 2,
                width: 1,
                display: 'grid',
                gridTemplateColumns: '180px 1fr',
                flexGrow: 1,
                [theme.breakpoints.down(insightsBreakpoint)]: {
                  gridTemplateColumns: '1fr',
                },
              })}
            >
              <InsightsMenu />

              <Box
                sx={(theme) => ({
                  position: 'relative',
                  alignSelf: 'stretch',
                  display: 'flex',
                  flexDirection: 'column',
                  bgcolor: 'background.paper',
                  borderRadius: 2,
                  [theme.breakpoints.down(insightsBreakpoint)]: {
                    aspectRatio: '1/1',
                    minWidth: 0,
                    width: 1,
                    bgcolor: 'transparent',
                  },
                })}
              >
                <AnimatedStateContent
                  getContent={(page) => (
                    <>
                      <Box
                        sx={{
                          width: 1,
                          height: 1,
                          maxHeight: '100%',
                        }}
                      >
                        {page === InsightsPage.REGISTRATIONS && (
                          <InsightsTicketsGraph
                            sx={{
                              width: 1,
                              height: 1,
                            }}
                          />
                        )}

                        {page === InsightsPage.REACH && (
                          <InsightsReach
                            sx={(theme) => ({
                              p: 3,
                              height: 1,
                              width: 1,
                              [theme.breakpoints.down(insightsBreakpoint)]: {
                                p: 0,
                                pt: 2,
                                borderTop: `1px solid ${theme.palette.divider}`,
                              },
                            })}
                          />
                        )}
                        {page === InsightsPage.GUESTS && (
                          <InsightsGuests
                            sx={(theme) => ({
                              p: 3,
                              height: 1,
                              width: 1,
                              [theme.breakpoints.down(insightsBreakpoint)]: {
                                p: 0,
                                pt: 2,
                                borderTop: `1px solid ${theme.palette.divider}`,
                              },
                            })}
                          />
                        )}
                      </Box>
                    </>
                  )}
                  state={page}
                  animateHeight={false}
                />
              </Box>
            </Row>

            <InsightsHighlightsBar />
          </Column>
        </DialogContent>
      </FullscreenDialog>
    </insightsContext.Provider>
  )
}

export default InsightsModal
