import { Typography } from '@mui/material'
import Column from '../../Column'
import { InsightHighlight, insightsBreakpoint } from '../../../types/insights'
import { formatShortNumber } from '../../../utils/eventFunctions'
import { useTranslation } from 'react-i18next'

interface Props {
  item: InsightHighlight
}

const InsightsHighlightItem = ({ item }: Props) => {
  const { t } = useTranslation('common')

  const getNumber = () => {
    if (item.value_type === 'percentage') {
      return `${item.value / 10}%`
    } else if (item.value_type === 'multiplier') {
      return `${item.value / 1000}x`
    } else if (item.value_type === 'count') {
      return formatShortNumber(item.value)
    }
  }

  return (
    <Column
      sx={(theme) => ({
        flexGrow: 1,
        flexShrink: 1,
        alignItems: 'center',
        justifyContent: 'center',
        gap: 0.5,
        bgcolor: 'background.paper',
        borderRadius: 1.5,
        height: 86,
        px: 1,
        [theme.breakpoints.down(insightsBreakpoint)]: {
          height: 'auto',
          width: 1,
          flexDirection: 'row',
          p: 2,
          justifyContent: 'space-between',
        },
      })}
    >
      <Typography
        variant='h7'
        sx={(theme) => ({
          textAlign: 'center',
          [theme.breakpoints.down(insightsBreakpoint)]: {
            textAlign: 'end',
            order: 1,
          },
        })}
      >
        {getNumber()}
      </Typography>
      <Typography
        variant='body2'
        sx={(theme) => ({
          textAlign: 'center',
          [theme.breakpoints.down(insightsBreakpoint)]: {
            textAlign: 'start',
          },
        })}
      >
        {t(`insightsModal.highlight_${item.type}`)}
      </Typography>
    </Column>
  )
}

export default InsightsHighlightItem
