import { InsightsPage } from '../../../types/insights'
import { Box, SxProps, Typography } from '@mui/material'
import Column from '../../Column'
import { useTranslation } from 'react-i18next'
import { Reply, SupervisorAccount } from '@mui/icons-material'
import AnimatedStateContent from '../../AnimatedStateContent'

interface Props {
  page: InsightsPage
  sx?: SxProps
}

const InsightsGraphEmptyState = ({ page, sx = [] }: Props) => {
  const { t } = useTranslation('common')

  const iconSx: SxProps = {
    width: 40,
    height: 40,
  }

  return (
    <Box
      sx={[
        (theme) => ({
          background: theme.palette.background.paper + '99', // 99 = 60% opacity
          overflow: 'hidden',
          height: 1,
          width: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 2,
          '& div': {
            width: 1,
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <AnimatedStateContent
        getContent={(page) => (
          <>
            <Column
              sx={{
                gap: 2.5,
                alignItems: 'center',
                color: 'grey.600',
              }}
            >
              <Column
                sx={{
                  gap: 0.5,
                  alignItems: 'center',
                }}
              >
                {page === InsightsPage.REGISTRATIONS && (
                  <SupervisorAccount sx={iconSx} />
                )}
                {page === InsightsPage.SHARES && (
                  <Reply
                    sx={{
                      ...iconSx,
                      transform: 'scaleX(-1)',
                    }}
                  />
                )}

                <Typography variant='subtitle1'>
                  {t(`insightsModal.emptyTitle_${page}`)}
                </Typography>
              </Column>

              <Typography variant='body2High'>
                {t(`insightsModal.emptyDescription_${page}`)}
              </Typography>
            </Column>
          </>
        )}
        state={page}
      />
    </Box>
  )
}

export default InsightsGraphEmptyState
