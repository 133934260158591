import * as React from 'react'
import NoSsr from '@mui/material/NoSsr'
import Popper from '@mui/material/Popper'
import { useItemTooltip, useMouseTracker } from '@mui/x-charts/ChartsTooltip'
import { useSvgRef, useXAxis } from '@mui/x-charts/hooks'
import { Typography } from '@mui/material'
import {
  generateVirtualElement,
  MousePosition,
} from '../generateVirtualElement'
import Column from '../../../Column'
import Row from '../../../Row'

const InsightsGraphTooltip = () => {
  const tooltipData = useItemTooltip()
  const mousePosition = useMouseTracker()
  const xAxis = useXAxis()
  const svgRef = useSvgRef()

  if (!tooltipData || !mousePosition || !xAxis.data) {
    // No data to display
    return null
  }

  if (svgRef.current === null) {
    return null
  }

  const tooltipPosition: MousePosition = {
    ...mousePosition,
  }

  const tempItems = [
    {
      type: 'WIDGET',
      percentage: 430,
    },
    {
      type: 'SHARES',
      percentage: 570,
    },
  ]

  return (
    <NoSsr>
      <Popper
        sx={{
          pointerEvents: 'none',
          zIndex: 10000,
        }}
        open
        placement={'top-start'}
        anchorEl={generateVirtualElement(tooltipPosition)}
      >
        <Column
          sx={{
            gap: 1.5,
            p: 1.5,
            bgcolor: 'background.paper',
            borderRadius: 0.75,
            border: `1px solid ${tooltipData.color}`,
          }}
        >
          <Typography
            variant='overline'
            sx={{
              fontSize: '0.5rem',
            }}
          >
            SOURCE TEXT
          </Typography>
          <Column
            sx={{
              gap: 1,
            }}
          >
            {tempItems.map((i) => (
              <Row
                key={i.type}
                sx={{
                  width: 1,
                  justifyContent: 'space-between',
                }}
              >
                <Typography
                  variant='captionSmall'
                  sx={{
                    color: 'text.secondary',
                  }}
                >
                  {i.type}
                </Typography>

                <Typography variant='captionSmall'>
                  {`${i.percentage / 10}%`}
                </Typography>
              </Row>
            ))}
          </Column>
        </Column>
      </Popper>
    </NoSsr>
  )
}

export default InsightsGraphTooltip
