import { HighlightItemData, LegendRendererProps } from '@mui/x-charts'

// Mostly copied from https://github.com/mui/mui-x/blob/v7.28.1/packages/x-charts/src/ChartsLegend/LegendPerItem.tsx
// Needed to change the rendered items to BASH style
interface InsightsLegendProps extends LegendRendererProps {
  highlightedItem?: HighlightItemData | null
}

const InsightsBarLegend = (props: InsightsLegendProps) => {
  return null
  // const {
  //   position,
  //   direction,
  //   seriesToDisplay,
  //   classes,
  //   itemMarkWidth = 6,
  //   itemMarkHeight = 6,
  //   markGap = 6,
  //   itemGap = 4,
  //   padding: paddingProps = 0,
  //   labelStyle: inLabelStyle,
  //   highlightedItem,
  // } = props
  // const theme = useTheme()
  // const drawingArea = useDrawingArea()
  //
  // const labelStyle = useMemo(
  //   () =>
  //     ({
  //       ...theme.typography.subtitle1,
  //       dominantBaseline: 'central',
  //       textAnchor: 'start',
  //       fontFamily: fontFamily,
  //       fontWeight: 400,
  //       fontSize: '0.5rem',
  //       lineHeight: '100%',
  //       letterSpacing: '0.2px',
  //       color: theme.palette.grey[800],
  //       fill: theme.palette.grey[800],
  //       ...inLabelStyle,
  //     }) as ChartsTextStyle, // To say to TS that the dominantBaseline and textAnchor are correct
  //   [inLabelStyle, theme],
  // )
  //
  // const padding = useMemo(
  //   // () => getStandardizedPadding(paddingProps),
  //   () => ({
  //     top: 0,
  //     left: 0,
  //     bottom: 0,
  //     right: 0,
  //   }),
  //   [paddingProps],
  // )
  //
  // const getItemSpace: GetItemSpaceType = useCallback(
  //   (label, inStyle = {}) => {
  //     const { rotate, dominantBaseline, ...style } = inStyle
  //     const linesSize = getWordsByLines({
  //       style,
  //       needsComputation: true,
  //       text: label,
  //     })
  //     const innerSize = {
  //       innerWidth:
  //         itemMarkWidth +
  //         markGap +
  //         Math.max(...linesSize.map((size) => size.width)),
  //       innerHeight: Math.max(
  //         itemMarkHeight,
  //         linesSize.length * linesSize[0].height,
  //       ),
  //     }
  //
  //     return {
  //       ...innerSize,
  //       outerWidth: innerSize.innerWidth + itemGap,
  //       outerHeight: innerSize.innerHeight + itemGap,
  //     }
  //   },
  //   [itemGap, itemMarkHeight, itemMarkWidth, markGap],
  // )
  //
  // const totalWidth = drawingArea.left + drawingArea.width + drawingArea.right
  // const totalHeight = drawingArea.top + drawingArea.height + drawingArea.bottom
  // const availableWidth = totalWidth - padding.left - padding.right
  // const availableHeight = totalHeight - padding.top - padding.bottom
  //
  // const [itemsWithPosition, legendWidth, legendHeight] = useMemo(
  //   () =>
  //     legendItemPlacements(
  //       seriesToDisplay,
  //       getItemSpace,
  //       labelStyle,
  //       direction,
  //       availableWidth,
  //       availableHeight,
  //       itemGap,
  //     ),
  //   [
  //     seriesToDisplay,
  //     getItemSpace,
  //     labelStyle,
  //     direction,
  //     availableWidth,
  //     availableHeight,
  //     itemGap,
  //   ],
  // )
  //
  // const gapX = useMemo(() => {
  //   switch (position.horizontal) {
  //     case 'left':
  //       return padding.left
  //     case 'right':
  //       return totalWidth - padding.right - legendWidth
  //     default:
  //       return (totalWidth - legendWidth) / 2
  //   }
  // }, [
  //   position.horizontal,
  //   padding.left,
  //   padding.right,
  //   totalWidth,
  //   legendWidth,
  // ])
  //
  // const gapY = useMemo(() => {
  //   switch (position.vertical) {
  //     case 'top':
  //       return padding.top
  //     case 'bottom':
  //       return totalHeight - padding.bottom - legendHeight
  //     default:
  //       return (totalHeight - legendHeight) / 2
  //   }
  // }, [
  //   position.vertical,
  //   padding.top,
  //   padding.bottom,
  //   totalHeight,
  //   legendHeight,
  // ])
  //
  // return (
  //   <ChartsLegendRoot className={props.classes?.root}>
  //     {itemsWithPosition.map((item, i) => (
  //       <InsightsLegendItem
  //         {...item}
  //         key={item.id}
  //         gapX={gapX}
  //         gapY={gapY}
  //         legendWidth={legendWidth}
  //         itemMarkHeight={itemMarkHeight}
  //         itemMarkWidth={itemMarkWidth}
  //         markGap={markGap}
  //         labelStyle={labelStyle}
  //         classes={classes}
  //         onClick={undefined}
  //         isHighlighted={item.rowIndex === highlightedItem?.dataIndex}
  //       />
  //     ))}
  //   </ChartsLegendRoot>
  // )
}

export default InsightsBarLegend
