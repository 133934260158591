import { BarLabelProps } from '@mui/x-charts'
// import { useBarSeries } from '@mui/x-charts/hooks/useSeries'

const InsightsBarLabel = ({
  seriesId,
  dataIndex,
  color,
  isFaded,
  isHighlighted,
  style,
}: BarLabelProps) => {
  return null
  // const themeHook = useTheme()
  // // const series = useBarSeries()
  // const yScale = useYScale()
  // if (!series) return
  //
  // const thisSeries = series.series[seriesId]
  // const yValue = thisSeries.data[dataIndex] ?? 0
  //
  // const svgYPosition = (yScale(yValue) ?? 0) - 4
  // // @ts-expect-error x exists
  // const svgXPosition = style.x.animation.to
  //
  // return (
  //   <text
  //     style={{
  //       transform: `translate(${svgXPosition}px, ${svgYPosition}px)`,
  //       textAnchor: 'middle',
  //       fontFamily: fontFamily,
  //       fontWeight: 500,
  //       fontSize: '0.5rem',
  //       lineHeight: '100%',
  //       letterSpacing: '0.2px',
  //       textAlign: 'center',
  //       fill: themeHook.palette.text.primary,
  //     }}
  //   >
  //     {yValue}
  //   </text>
  // )
}

export default InsightsBarLabel
