import React from 'react'
import dynamic from 'next/dynamic'
import { makeStyles } from 'tss-react/mui'

const Chart = dynamic(() => import('react-charts').then((mod) => mod.Chart), {
  ssr: false,
})

const useStyles = makeStyles()((theme) => ({
  chartContainer: {
    width: '100%',
    height: 400,
  },
}))

const stateOrder = {
  LIVE: 5,
  REJECTED: 4,
  INFEASIBLE: 3,
  MANUAL: 2,
  NEW: 1,
  TEST_RUN: 0,
}

export default function ScrapersByRegionChart({ data }) {
  const { classes } = useStyles()
  const primaryAxis = React.useMemo(
    () => ({
      getValue: (datum) => datum.primary,
    }),
    [],
  )

  const secondaryAxes = React.useMemo(
    () => [
      {
        getValue: (datum) => datum.secondary,
        stacked: true,
      },
    ],
    [],
  )
  if (data == null) return null

  const regionsMap = new Map(data.regions.map((r) => [r.id, r.name]))
  const regionNames = [...regionsMap.values(), 'Other']

  const totalCountByRegion = new Map(regionNames.map((rn) => [rn, 0]))

  const transformedData = Object.values(
    data.counts.reduce((acc, d) => {
      const label = d.state
      const primary = regionsMap.get(d.scraperRegionId) ?? 'Other'
      const secondary = d._count._all
      totalCountByRegion.set(
        primary,
        totalCountByRegion.get(primary) + secondary,
      )

      if (!acc[label]) {
        acc[label] = {
          label,
          data: [],
        }
      }
      acc[label].data.push({
        primary,
        secondary,
      })

      return acc
    }, {}),
  )

  const filledTransformedData = transformedData
    .map((d) => ({
      label: d.label,
      data: [
        ...d.data,
        ...regionNames
          .filter((rn) => !d.data.find((dd) => dd.primary === rn))
          .map((rn) => ({
            primary: rn,
            secondary: 0,
          })),
      ].sort((a, b) => {
        const aTotal = totalCountByRegion.get(a.primary)
        const bTotal = totalCountByRegion.get(b.primary)
        return bTotal - aTotal
      }),
    }))
    .sort((a, b) => stateOrder[b.label] - stateOrder[a.label])
  return (
    <div className={classes.chartContainer}>
      <Chart
        options={{
          data: filledTransformedData,
          primaryAxis,
          secondaryAxes,
        }}
      />
    </div>
  )
}
