import Row from '../../../Row'
import Column from '../../../Column'
import {
  Box,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import InsightsBarGraph from './InsightsBarGraph'
import { Theme } from '@mui/material/styles'
import AnimatedStateContent from '../../../AnimatedStateContent'
import { useState } from 'react'
import {
  insightsBreakpoint,
  InsightsReachType,
} from '../../../../types/insights'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'

interface Props {
  sx?: SxProps<Theme>
}

const InsightsReach = ({ sx = [] }: Props) => {
  const [page, setPage] = useState<number>(0)
  const themeHook = useTheme()

  // In this case, using useMediaQuery is fine as this component is never shown on the first render of a page
  // If it was shown on the first render, you should always use breakpoints in css instead of in javascript
  const isMobile = useMediaQuery(themeHook.breakpoints.down(insightsBreakpoint))

  const onLeftClick = () => {
    setPage((prev) => Math.max(0, prev - 1))
  }

  const onRightClick = () => {
    setPage((prev) =>
      Math.min(Object.keys(InsightsReachType).length - 1, prev + 1),
    )
  }

  return (
    <Row
      sx={[
        {
          justifyContent: 'space-between',
          gap: 3,
          position: 'relative',
          width: 1,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Row
        sx={{
          position: 'relative',
          width: 1,
          gap: 3,
        }}
      >
        <AnimatedStateContent
          getContent={(page) => (
            <>
              {(isMobile ? [page] : [0, 1, 2]).map((i) => (
                <Column
                  key={i}
                  sx={{
                    gap: 2,
                    height: 1,
                    alignItems: 'center',
                    flexGrow: 1,
                    width: 1,
                  }}
                >
                  <Row
                    sx={{
                      width: 1,
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant='overline'>TEXT HERE</Typography>

                    <Row
                      sx={(theme) => ({
                        gap: 0.75,
                        alignItems: 'center',
                        display: 'none',
                        [theme.breakpoints.down(insightsBreakpoint)]: {
                          display: 'flex',
                        },
                      })}
                    >
                      <ChevronLeft onClick={onLeftClick} />
                      <ChevronRight onClick={onRightClick} />
                    </Row>
                  </Row>
                  <Box
                    sx={(theme) => ({
                      display: 'flex',
                      position: 'relative',
                      flexGrow: 1,
                      // height: 1,
                      width: 1,
                      // flexShrink: 1,
                      minHeight: 0,
                      minWidth: '100%',
                      [theme.breakpoints.down(insightsBreakpoint)]: {
                        bgcolor: 'background.paper',
                        p: 3,
                        borderRadius: 1,
                      },
                    })}
                  >
                    <InsightsBarGraph
                      data={[
                        {
                          count: 400,
                          items: [],
                          type: 'VIEW',
                        },
                        {
                          count: 500,
                          items: [],
                          type: 'SHARE',
                        },
                        {
                          count: 100,
                          items: [],
                          type: 'TICKET',
                        },
                      ]}
                    />
                  </Box>
                </Column>
              ))}
            </>
          )}
          state={page}
          animateHeight={false}
        />
      </Row>
    </Row>
  )
}

export default InsightsReach
