import {
  insightsBreakpoint,
  InsightsPieDataItem,
} from '../../../../types/insights'
import { HighlightItemData, LegendRendererProps, PieChart } from '@mui/x-charts'
import { useMediaQuery, useTheme } from '@mui/material'
import InsightsBarLegend from '../Reach/Legend/InsightsBarLegend'
import { fontFamily } from '../../../../shared-components/typography'
import { useState } from 'react'

interface Props {
  data: InsightsPieDataItem[]
}

const InsightsPieGraph = ({ data }: Props) => {
  const themeHook = useTheme()
  const isMobile = useMediaQuery(themeHook.breakpoints.down(insightsBreakpoint))
  const [highlightedItem, setHighlightedItem] =
    useState<HighlightItemData | null>(null)

  const Legend = (props: LegendRendererProps) => (
    <InsightsBarLegend highlightedItem={highlightedItem} {...props} />
  )

  return (
    <PieChart
      highlightedItem={highlightedItem}
      onHighlightChange={setHighlightedItem}
      resolveSizeBeforeRender
      tooltip={{
        trigger: 'none',
      }}
      margin={{
        top: 0,
        right: 16,
        bottom: 0,
        left: 16,
      }}
      series={[
        {
          data: data.map((d) => ({
            id: d.text,
            value: d.promille,
            label: d.text,
          })),
          innerRadius: '65%',
          cy: isMobile ? undefined : '35%',
          paddingAngle: 1.5,
          highlightScope: { fade: 'global', highlight: 'item' },
          highlighted: {
            // additionalRadius: 1,
            additionalRadius: 0,
          },
          faded: {
            additionalRadius: 0,
          },
        },
      ]}
      colors={[
        themeHook.palette.grey[800],
        themeHook.palette.grey[600],
        themeHook.palette.grey[400],
        themeHook.palette.grey[200],
      ]}
      slotProps={{
        legend: {
          position: {
            vertical: isMobile ? 'middle' : 'bottom',
            horizontal: 'middle',
          },
          labelStyle: {
            fontFamily: fontFamily,
            fontWeight: 400,
            fontSize: '0.5rem',
            lineHeight: '100%',
            letterSpacing: '0.2px',
            color: themeHook.palette.grey[800],
          },
        },
      }}
      slots={{
        legend: Legend,
      }}
      sx={(theme) => ({
        '& .MuiPieArc-highlighted': {
          stroke: theme.palette.primary.main,
        },
        '& .MuiPieArc-faded': {
          // opacity: 0.7,
          opacity: 1,
        },
      })}
    ></PieChart>
  )
}

export default InsightsPieGraph
